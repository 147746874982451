/* 
import React, { useState, useEffect } from 'react';
import { io } from 'socket.io-client';

const Counterpage = () => {
  const [onlineUsers, setOnlineUsers] = useState(0);

  useEffect(() => {
    const socket = io('http://localhost:5000/', {
      transports: ['websocket'],  
    });

    // Escuchar actualizaciones del contador de usuarios en línea
    socket.on('updateOnlineUsers', (count) => {
      setOnlineUsers(count);
    });

    // Limpiar la conexión al desmontar el componente
    return () => {
      socket.disconnect();
    };
  }, []);

  return (
    <div>
      <h2>Usuarios en línea: {onlineUsers}</h2>
    </div>
  );
};

export default Counterpage;

 */

import React, { useState, useEffect } from 'react';
import { io } from 'socket.io-client';
import { Users } from 'lucide-react'; // Importar el ícono Users

const Counterpage = () => {
  const [onlineUsers, setOnlineUsers] = useState(0);
  const [isVisible, setIsVisible] = useState(true); // Estado para controlar la visibilidad

  useEffect(() => {
    // Conectar con el servidor de Socket.IO
    const socket = io('https://database-production-61e0.up.railway.app/', {
/*     const socket = io('http://localhost:5000', { */
      transports: ['websocket'], // Usar WebSocket como transporte principal
    });

    // Escuchar actualizaciones del contador de usuarios en línea
    socket.on('updateOnlineUsers', (count) => {
      setOnlineUsers(count);
      setIsVisible(true); // Mostrar el componente cuando hay una actualización

      // Ocultar el componente después de 3 segundos (opcional)
      setTimeout(() => {
        setIsVisible(false);
      }, 3000);
    });

    // Limpiar la conexión al desmontar el componente
    return () => {
      socket.disconnect();
    };
  }, []);

  return (
    <div className="top-4 right-4 bg-transparent backdrop-blur-lg shadow-lg rounded-full px-4 py-2 h-[25px] w-[231px] flex items-center gap-2 transition-all duration-300 ease-in-out">

      <div className={`transition-opacity duration-300 ${isVisible ? 'opacity-100' : 'opacity-40'}`}>
        <Users className="w-4 h-4 text-indigo-600" />
      </div>

      <span className="font-medium text-gray-700">
        {onlineUsers} {onlineUsers === 1 ? 'User' : 'Users'} purchasing
      </span>

      <div className={`w-2 h-2 rounded-full bg-green-500 ${isVisible ? 'opacity-100' : 'opacity-40'}`} />
    </div>
  );
};

export default Counterpage;

/* import React, { useState, useEffect } from 'react';
import Pusher from 'pusher-js';

const Counterpage = () => {
  const [onlineUsers, setOnlineUsers] = useState(0);

  useEffect(() => {
    
    const pusher = new Pusher('dfa3e1edf9db1eaea51a', {
      cluster: 'us3',
      forceTLS: true,
    });

    // subscribing to the channel 'online-users'
    const channel = pusher.subscribe('online-users');

    // Escuchar el evento 'update'
    channel.bind('update', (data) => {
      setOnlineUsers(data.onlineUsers);
    });

    // Notify to the backend user is logged or on 
    fetch('https://database-five-rose.vercel.app/update-online-users', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ action: 'connect' }),
      credentials: 'include',
    });

    // clean up the subscription and notify to the backend user is disconnected or off
    return () => {
      fetch('https://database-five-rose.vercel.app/update-online-users', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ action: 'disconnect' }),
        credentials: 'include',
      });

      pusher.unsubscribe('online-users');
    };
  }, []);

  return (
    <div className="top-4 right-4 bg-transparent backdrop-blur-lg shadow-lg rounded-full px-4 py-2 h-[25px] w-[199px] flex items-center gap-2 transition-all duration-300 ease-in-out">
      <span className="font-medium text-gray-700">
        {onlineUsers} {onlineUsers === 1 ? 'user' : 'users'} Purchasing
      </span>
      <div className="w-2 h-2 rounded-full bg-green-500" />
    </div>
  );
};

export default Counterpage; */


