import React, { useState, useEffect } from 'react';
import { Sparkles, Tag, ArrowRight, X } from 'lucide-react';
import imgbnner from '../assets/imgs/imgbanner.png';

function PromotionalBanner() {
  const [isVisible, setIsVisible] = useState(false);
  const [isBannerOpen, setIsBannerOpen] = useState(true);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  if (!isBannerOpen) return null;

  return (
    <div className="p-1">
      <div 
        className={`w-full bg-gradient-to-r from-orange-500 to-orange-600 rounded-lg shadow-[0_0_15px_rgba(251,146,60,0.5)] transition-all duration-700 transform ${
          isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'
        }`}
      >
        <div className="relative overflow-hidden rounded-lg">
          {/* Neon border effect */}
          <div className="absolute inset-0 border-2 border-orange-400 rounded-lg animate-pulse"></div>
          
          <div className="relative py-2 px-4">
            {/* Close button */}
            <button 
              onClick={() => setIsBannerOpen(false)}
              className="absolute top-1/2 right-3 -translate-y-1/2 text-orange-100 hover:text-white transition-colors"
              aria-label="Cerrar banner"
            >
              <X size={18} />
            </button>
            
            {/* Main content */}
            <div className="flex flex-wrap items-center gap-x-4 gap-y-2 pr-8">
              {/* Sparkle and tag section */}
              <div className="flex items-center gap-2 min-w-fit">
                <div className="text-orange-200 animate-spin-slow">
                  <Sparkles size={16} />
                </div>
                <Tag className="text-orange-100" size={14} />
                <span className="text-orange-100 font-semibold tracking-wider text-sm whitespace-nowrap">
                  Special March's Offer
                </span>
              </div>
              
              {/* Discount text */}
              <h2 className="text-lg font-bold text-white tracking-tight whitespace-nowrap">
                15% Discount all services!
              </h2>
              
              
              {/* Percentage circle - shows only on larger screens */}
              <div className="hidden md:flex h-8 w-8 rounded-full bg-orange-400/20 items-center justify-center">
                <span className="text-sm font-bold text-white">
                  15%
                </span>
                
              </div>
                <img src={imgbnner} alt="discountimage" border="0" style={{height: '45px', width:'45px'}}/> 
              {/* Action button */}
              <button className="ml-auto group inline-flex items-center gap-1 bg-white text-orange-600 px-3 py-1 rounded-full text-sm font-semibold transition-all hover:bg-orange-100 hover:shadow-lg hover:scale-105">
                <a className='no-underline text-orange-600' rel="calendar" href="https://cal.com/nalabusiness/15min"> 
                Get in touch
                </a>
                <ArrowRight className="transition-transform group-hover:translate-x-1" size={14} />
              </button>
            </div>  
          </div>
        </div>
      </div>
    </div>
  );
}

export default PromotionalBanner;