import { FileTextIcon } from "@radix-ui/react-icons";
import { BellIcon, Share2Icon } from "lucide-react";
import "../assets/main.css"
import { cn } from "../utils/cn";
import { AnimatedBeamMultipleOutputDemo } from "../components/Animatedbean";
import { AnimatedListDemo } from "../components/Animatedchildlist";
import { BentoCard, BentoGrid } from "../Bentogrid";
import { MarqueeDemo } from "../components/Marquee";
import { Gifweb } from "../components/Gifweb";


const files = [
  {
    name: "bitcoin.pdf",
    body: "Bitcoin is a cryptocurrency invented in 2008 by an unknown person or group of people using the name Satoshi Nakamoto.",
  },
  {
    name: "finances.xlsx",
    body: "A spreadsheet or worksheet is a file made of rows and columns that help sort data, arrange data easily, and calculate numerical data.",
  },
  {
    name: "logo.svg",
    body: "Scalable Vector Graphics is an Extensible Markup Language-based vector image format for two-dimensional graphics with support for interactivity and animation.",
  },
  {
    name: "keys.gpg",
    body: "GPG keys are used to encrypt and decrypt email, files, directories, and whole disk partitions and to authenticate messages.",
  },
  {
    name: "seed.txt",
    body: "A seed phrase, seed recovery phrase or backup seed phrase is a list of words which store all the information needed to recover Bitcoin funds on-chain.",
  },
];

const PlaceholderIcon = () => <div className="h-12 w-12 bg-gray-300" />;

const features = [
  {
    Icon: FileTextIcon || PlaceholderIcon,
    name: "Get Backup",
    description: "We constantly save your Projects as you need.",
    href: "#",
    cta: "Ask for it",
    className: "col-span-3 lg:col-span-1",
    background: (
      <MarqueeDemo
        pauseOnHover
        className="absolute top-10 [--duration:20s] [mask-image:linear-gradient(to_top,transparent_40%,#000_100%)]  "
      >
        {files.map((f, idx) => (
          <figure
            key={idx}
            className={cn(
              "relative w-32 cursor-pointer overflow-hidden rounded-xl border p-4",
              "border-gray-950/[.1] bg-gray-950/[.01] hover:bg-gray-950/[.05]",
              "dark:border-gray-50/[.1] dark:bg-gray-50/[.10] dark:hover:bg-gray-50/[.15]",
              "transform-gpu blur-[1px] transition-all duration-300 ease-out hover:blur-none",
            )}
          >
            <div className="flex flex-row items-center gap-2">
              <div className="flex flex-col">
                <figcaption className="text-sm font-medium dark:text-white">
                  {f.name}
                </figcaption>
              </div>
            </div>
            <blockquote className="mt-2 text-xs">{f.body}</blockquote>
          </figure>
        ))}
      </MarqueeDemo>
    ),
  },
  {
    Icon: BellIcon || PlaceholderIcon,
    name: "Fluid communication",
    description: "Get notified when something happens.",
    href: "https://api.whatsapp.com/send?phone=3143468608",
    cta: "Reach us",
    className: "col-span-3 lg:col-span-2",
    background: (
      <AnimatedListDemo className="absolute right-2 top-4 h-[300px] w-full border-none  transition-all duration-300 ease-out [mask-image:linear-gradient(to_top,transparent_10%,#000_100%)] group-hover:scale-105" />
    ),
  },
  {
    Icon: Share2Icon,
    name: "Integrations",
    description: "Supports 100+ integrations and counting.",
    href: "/Secondproject",
    cta: "Learn more",
    className: "col-span-3 lg:col-span-2",
    background: (
      <AnimatedBeamMultipleOutputDemo className="absolute right-2 top-4 h-[300px] border-none transition-all duration-300 ease-out [mask-image:linear-gradient(to_top,transparent_10%,#000_100%)] group-hover:scale-105" />
    ),
  },
  {
    Icon: Share2Icon,
    name: "Web Design",
    description: "We help you code your website.",
    href: "https://api.whatsapp.com/send?phone=3143468608",
    cta: "Contact us now",
    className: "col-span-3 lg:col-span-1",
    background: <Gifweb />,
  }
];

export function BentoDemo() {
  return (
    <BentoGrid>
      {features.map((feature, index) => (
          <BentoCard key={index} {...feature} />
      ))}
    </BentoGrid>
  );
}

  