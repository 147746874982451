import { HeroVideoDialog } from "../HeroVideoDialog";
import video from "../assets/imgs/NalADD.mp4";
import imagelocal from "../assets/imgs/Nala.png";

export function HeroVideoDialogDemo() {
  return (
    <div className="relative">
      <HeroVideoDialog
        className="dark:hidden block w-[300px] h-[300px]"
        animationStyle="from-center"
        videoSrc={video}
        thumbnailSrc={imagelocal} // Local thumbnail
        thumbnailAlt="Hero Video"
        controlsList="nodownload"
      />
      <HeroVideoDialog
        className="hidden dark:block w-[300px] h-[300px]"
        animationStyle="from-center"
        videoSrc={video}
        thumbnailSrc={imagelocal} // Dark mode thumbnail
        thumbnailAlt="Hero Video"
        controlsList="nodownload"
      />
    </div>
  );
}
